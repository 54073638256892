import React, { Component } from "react";
import Banner from "../../components/common/Banner";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import ExploreSidebar from "./components/ExploreSidebar";

export default class ExplorePrincipal extends Component {
  render() {
    return (
      <div>
        {/* Header */}
        <Header />

        {/* Banner */}
        <Banner title="Explore BIT" image="banners/banner-explore.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="section-title with-desc text-left clearfix">
                  <div className="title-header">
                    <h3 className="title">Principal</h3>
                  </div>
                </div>
                <div className="ttm-service-single-content-area">
                  <section className="ttm-row team-details-section clearfix">
                    <div className="container-xl">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="ttm-featured-wrapper">
                            <div className="featured-thumbnail text-center">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/assets/images/explore/principal.jpg`
                                }
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-8 justify"
                          style={{ margin: "auto" }}
                        >
                          <div className="ttm-team-member-content shadow-box res-991-mt-30">
                            <div className="ttm-team-member-content shadow-box res-991-mt-30">
                              <div className="ttm-team-member-single-list">
                                <a href="https://aswathmu.in" target="_blank">
                                  <h2 className="ttm-team-member-single-title">
                                    Dr. Aswath M U
                                  </h2>
                                </a>
                                <span className="ttm-team-member-single-position">
                                  B.E., M.E., Ph.D., FACCE, MICI, FIEI, MISTE,
                                  MACI
                                  <br />
                                  <a href="https://aswathmu.in" target="_blank">
                                    <span className="ttm-team-member-single-position">
                                      View Profile
                                    </span>
                                  </a>
                                  <hr />
                                </span>
                                <p>
                                  In these transformative times, it is essential
                                  for us to evolve, to create a sustainable
                                  future for our society, and to leverage
                                  technology to improve health management and
                                  environmental care. At Bangalore Institute of
                                  Technology (BIT), we are dedicated to raising
                                  awareness and making every effort to prepare
                                  our students for these modern challenges. The
                                  demands of industry and society from higher
                                  educational institutes are greater than ever,
                                  urging us to rethink our educational
                                  approaches and develop robust skillsets in
                                  future engineers.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 paddingtop-2 justify">
                          <p>
                            We are committed to implementing the National
                            Education Policy (NEP) 2020 in alignment with AICTE
                            and VTU guidelines. This policy, a visionary
                            framework, aims to reshape India into a global
                            knowledge superpower, bringing meaningful changes to
                            the education system. It aligns well with the United
                            Nations’ 17 Sustainable Development Goals (SDGs),
                            which cover the social, economic, and environmental
                            dimensions of development. Quality education is at
                            the heart of sustainable development, fostering
                            self-reliance, economic growth, and improved
                            livelihoods through skill development and enhanced
                            opportunities.
                          </p>
                          <br />
                          <p>
                            The fourth SDG—“Ensure inclusive and equitable
                            quality education and promote lifelong learning
                            opportunities for all”—is a call to action for
                            educators across sectors. At BIT, we are actively
                            participating in this mission to equip our future
                            engineers to meet complex global challenges.
                            Established in 1979 by the Rajya Vokkaligara Sangha,
                            BIT has emerged as a prominent institute for
                            engineering education. Inspired by the iconic lines
                            of Kuvempu, “ಓ ನನ್ನ ಚೇತನ ಆಗು ನೀ ಅನಿಕೇತನ- “O Nanna
                            Chetana, Aagu Nee Aniketana” (“Be unhoused O my
                            soul, only the infinite is your goal”), the
                            institution is dedicated to creating an environment
                            that fosters limitless learning.
                          </p>
                          <br />
                          <p>
                            It is my pleasure to welcome you to BIT. With
                            state-of-the-art laboratories, research centers,
                            workshops, libraries, and a highly qualified
                            faculty, BIT offers an inspiring educational
                            experience. The institute provides sports facilities
                            and supports around 30 student clubs for all-round
                            personality development. With over 100 Memorandums
                            of Understanding (MoUs) with companies, skill
                            development centers, an Idea Lab, a start-up center,
                            and multiple centers of excellence, students gain
                            invaluable opportunities for industrial internships
                            and experiential learning.
                          </p>
                          <br />
                          <p>
                            The training and placement cell at BIT has built
                            strong industry connections, boasting partnerships
                            with over 300 companies and maintaining an
                            impressive placement record. Our training programs
                            focus on essential skill development, and we offer
                            sessions on leadership and entrepreneurship to
                            prepare students for future roles.
                          </p>
                          <br />
                          <p>
                            BIT is affiliated with Visvesvaraya Technological
                            University (VTU), Karnataka, and approved by AICTE,
                            New Delhi. Our nine undergraduate programs have
                            received accreditation from the National Board of
                            Accreditation (NBA). Additionally, BIT is recognized
                            under sections 2(f) and 12(B) of the UGC Act of
                            1956. In the National Institutional Ranking
                            Framework (NIRF) India Rankings 2020, BIT ranks in
                            the 251-300 band, and it achieved a place in the
                            201-250 band in 2019. We are also honoured to have
                            received an A+ grade accreditation from NAAC.
                          </p>
                          <br />
                          <p>
                            In step with industry needs, innovation trends, and
                            a growing start up culture, we ensure our students
                            receive a broad knowledge base and skillset aligned
                            with both academic and practical industry demands.
                            Our graduates are nurtured to become socially
                            responsible engineers, equipped to meet societal
                            needs with character and a strong sense of civic
                            responsibility.
                          </p>
                          <br />
                          <p>
                            With the support of our qualified faculty, dedicated
                            staff, and advanced infrastructure, BIT is committed
                            to developing knowledgeable scholars for a
                            sustainable future. We continuously strive to create
                            a holistic learning environment that fosters
                            academic excellence and prepares our students to
                            excel in their chosen fields.
                          </p>
                          <br />
                          <p>
                            I extend my best wishes to all students as they
                            pursue their aspirations and work towards fulfilling
                            their dreams.
                          </p>
                          <br />
                          <br />
                          <p>With Best Regards</p>
                          <br />
                          <p style={{ fontWeight: "bold" }}>Prof. Aswath M U</p>
                          <p>Principal & Director</p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div className="col-lg-3 widget-area">
                <ExploreSidebar />
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}
